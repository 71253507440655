// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    user: null,
    login: false,
    token: null,
    role: null,
    tokenExpiryTime: null
  },
  reducers: {
    handleLogin: (state, action) => {
      state.user = action.payload.user || state.user
      state.login = action.payload.login || state.login
      state.token = action.payload.token || state.token
      state.role = action.payload.role || state.role
    },
    handleUser: (state, action) => {
      state.user = action.payload.user
    },
    handleTokenExpiryTime: (state, action) => {
      state.tokenExpiryTime = action.payload.tokenExpiryTime
    },
    handleLogout: state => {
      state.user = null
      state.login = false
      state.token = null
      state.role = null
      localStorage.setItem("token", "")
    }
  }
})

export const { handleLogin, handleLogout, handleUser, handleTokenExpiryTime } = authSlice.actions

export default authSlice.reducer
